<template>
  <div>
    <li class="xa-cell file-list_item">
      <div class="xa-cell xa-flex">
        <i class="el-icon-tickets"></i>
        <div class="xa-flex file-name">
          {{ file.name }}
        </div>
        <i class="xa-txt-12 file-size">{{ conver(file.size) }}</i>
      </div>
      <slot></slot>
    </li>
    <slot name="more"></slot>
  </div>
</template>
<script>
export default {
  props: {
    file: Object
  },
  methods: {
    conver(limit) {
      if (!limit) return ''
      var size = ''
      if (limit < 0.1 * 1024) {
        //如果小于0.1KB转化成B
        size = limit.toFixed(2) + 'B'
      } else if (limit < 0.1 * 1024 * 1024) {
        //如果小于0.1MB转化成KB
        size = (limit / 1024).toFixed(2) + 'KB'
      } else if (limit < 0.1 * 1024 * 1024 * 1024) {
        //如果小于0.1GB转化成MB
        size = (limit / (1024 * 1024)).toFixed(2) + 'MB'
      } else {
        //其他转化成GB
        size = (limit / (1024 * 1024 * 1024)).toFixed(2) + 'GB'
      }

      var sizestr = size + ''
      var len = sizestr.indexOf('.')
      var dec = sizestr.substr(len + 1, 2)
      if (dec == '00') {
        //当小数点后为00时 去掉小数部分
        return sizestr.substring(0, len) + sizestr.substr(len + 3, 2)
      }
      return sizestr
    }
  }
}
</script>

<style lang="scss" scoped>
.file-list_item {
  margin: 4px 0;
  padding: 0px 4px;
  line-height: 30px;
  &:hover {
    background: #ecf6fd;
  }
}
.file-size {
  padding-right: 2em;
}
.el-icon-tickets {
  margin-right: 8px;
  font-size: 16px;
}
</style>
