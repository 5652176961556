<template>
  <div class="upload-wrapper">
    <AlreadyExistFiles :items="value" />
    <div v-if="value.length === 0" class="empty-status xa-cell">
      <div>
        <i class="el-icon-receiving"></i>
        <p>没有相关数据</p>
      </div>
    </div>
  </div>
</template>
<script>
import AlreadyExistFiles from './AlreadyExistFiles.vue'
export default {
  name: 'fileUpload',
  components: {
    AlreadyExistFiles
  },
  props: {
    value: {
      type: Array
    }
  }
}
</script>
<style scoped lang="scss">
.upload-wrapper {
  box-sizing: border-box;
  padding: 4px;
  width: 100%;
  border-radius: 5px;
  font-size: 12px;
  user-select: none;
  border: 1px solid #ccc;
}
.empty-status {
  min-height: 66px;
  justify-content: center;
  text-align: center;
  color: #909399;
  .el-icon-receiving {
    font-size: 20px;
    margin-bottom: 4px;
  }
}
</style>
