<template>
  <div>
    <fileItem v-for="(file, index) in items" :key="index" :file="file">
      <div class="xa-cell">
        <div class="xa-color-danger">{{ file.tip }}</div>
        <div v-show="file.STATUS" @click="$emit('delete', file)" title="删除">
          <i class="file-icon_delete el-icon-delete"></i>
        </div>
      </div>
      <progressBar
        slot="more"
        v-if="!file.STATUS && !file.tip"
        :progress="file.PROGRESS"
      ></progressBar>
    </fileItem>
  </div>
</template>
<script>
import progressBar from './progressBar.vue'
import FileItem from './FileItem.vue'
export default {
  components: {
    progressBar,
    FileItem
  },
  props: {
    items: Array
  }
}
</script>
