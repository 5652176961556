<template>
  <div
    @dragover.prevent="onDragover"
    @drop.prevent="onDrop"
    @dragleave.prevent="onDragleave"
    class="upload-wrapper"
    :class="{ 'upload-wrapper-drag': drag, 'upload-wrapper-draging': dragOver }"
  >
    <div class="xa-cell">
      <div class="xa-flex">
        <input
          ref="input"
          type="file"
          :accept="accept"
          value="选择"
          style="display:none;"
          :onchange="onFilechange"
        />
        <el-button
          :disabled="isMaxStatus"
          @click="onFilesSelect"
          :plain="true"
          type="primary"
          size="small"
          icon="el-icon-upload"
        >
          {{ placeholder || '上传文件' }}
        </el-button>
      </div>
      <div v-if="max != -1" :class="{ 'xa-color-danger': isMaxStatus }">
        {{ value.length }}/{{ max }}
      </div>
    </div>
    <AlreadyExistFiles
      class="file-hasUpload"
      :items="hasUploadFileList"
      @delete="onExistFileDel"
      :removable="true"
    />
    <UploadFiles :items="uploadFileList" @delete="onFileDelete" />
    <div
      v-if="value.length === 0 && uploadFileList.length === 0"
      class="empty-status xa-cell"
    >
      <div>
        <i class="el-icon-receiving"></i>
        <p>没有相关数据</p>
      </div>
    </div>
  </div>
</template>
<script>
import { uploadFile } from '@/controllers/upload.js'
import AlreadyExistFiles from './AlreadyExistFiles.vue'
import UploadFiles from './UploadFiles.vue'
let throttle = function(delay, action) {
  var last = 0
  return function() {
    var curr = +new Date()
    if (curr - last > delay) {
      action.apply(this, arguments)
      last = curr
    }
  }
}
export default {
  name: 'fileUpload',
  components: {
    AlreadyExistFiles,
    UploadFiles
  },
  data() {
    return {
      hasUploadFileList: [], // 已经上传的文件
      uploadFileList: [], // 上传文件的信息
      dragOver: false // 拖拽时 是否有东西在上面
    }
  },
  props: {
    value: {
      type: Array
    },
    placeholder: {
      type: String,
      default: ''
    },
    accept: {
      type: String,
      default: '*'
    },
    drag: {
      type: Boolean,
      default: true
    },
    // 限制文件上传大小
    size: {
      type: Number,
      default: -1
    },
    // 上传文件的接口 有默认值
    action: {
      type: String,
      default: undefined
    },
    // 最大上传数量
    max: {
      type: Number,
      default: -1
    }
  },
  computed: {
    isMaxStatus() {
      return this.max > -1 && this.max === this.value.length
    }
  },
  methods: {
    onDragover: throttle(250, function(e) {
      e = e || window.event
      e.dataTransfer.dropEffect = 'copy'
      this.dragOver = true
      return false
    }),
    onDragleave() {
      this.dragOver = false
      return false
    },
    onDrop(e) {
      e = e || window.event
      this.handleSelectedFile(e.dataTransfer.files)
      return false
    },
    /**
     * 处理选择文件上传
     * 注意，由于可以拖拽多个文件，所以除了判断isMaxStatus，还要在文件数组里面判断
     */
    handleSelectedFile(files) {
      if (this.isMaxStatus) return
      let fileList = Array.from(files)
      fileList.forEach(({ name, lastModified, size, type }, index) => {
        if (this.max > -1 && this.value.length + index + 1 > this.max) return
        let fileInfo = {
          name,
          lastModified,
          size,
          type,
          STATUS: false,
          PROGRESS: 0,
          guid: -1
        }
        this.uploadFileList.push(fileInfo)
        if (this.filterFile(fileInfo) === false) return
        uploadFile(this.action, {
          files: [fileList[index]],
          onprogress(e) {
            fileInfo.PROGRESS = Math.floor((100 * e.loaded) / e.total)
          }
        }).then(data => {
          fileInfo.STATUS = true
          this.$nextTick(function() {
            this.submit(data[0], fileInfo.name)
            fileInfo.guid = data[0]
          })
        })
      })
    },
    onFilechange() {
      let input = this.$refs.input
      if (input.files && input.files.length) {
        this.handleSelectedFile(input.files)
      }
    },
    filterFile(fileInfo) {
      if (this.size > 0) {
        if (fileInfo.size > this.size) {
          fileInfo.tip = '超出上传大小限制'
          return false
        }
      }
      return true
    },
    onFilesSelect() {
      let input = this.$refs.input
      input.onchange = this.onFilechange
      input.click()
    },
    // 现在每次上传都只可以是一个文件  所以 datas是数组长度为一
    submit(guid, name) {
      this.value.push({
        guid,
        name
      })
    },
    onExistFileDel(file) {
      this.onFileDelete(file, 'old')
    },
    onFileDelete(file, type = 'new') {
      let index = 0
      if (type === 'new') {
        index = this.uploadFileList.indexOf(file)
        this.uploadFileList.splice(index, 1)
        index = this.value.findIndex(item => item.guid === file.guid)
      } else {
        index = this.hasUploadFileList.indexOf(file)
        this.hasUploadFileList.splice(index, 1)
        index = this.value.findIndex(item => item.href === file.href)
      }
      this.value.splice(index, 1)
    }
  },
  mounted() {
    if (this.value && this.value.length) {
      this.hasUploadFileList = Array.from(this.value)
    }
  }
}
</script>
<style scoped lang="scss">
.upload-wrapper {
  box-sizing: border-box;
  padding: 4px;
  width: 100%;
  min-height: 120px;
  border-radius: 5px;
  font-size: 12px;
  user-select: none;
}

.upload-wrapper-drag {
  border: 1px dashed #ccc;
}

.upload-wrapper-draging {
  border-color: #04bf02;
}

.file-icon_delete {
  cursor: pointer;
  &:hover {
    color: $color-red;
  }
}
/deep/ [class*='el-icon-'] {
  font-size: 16px;
  & + & {
    margin-left: 16px;
  }
}
.empty-status {
  min-height: 66px;
  justify-content: center;
  text-align: center;
  color: #909399;
  .el-icon-receiving {
    font-size: 20px;
    margin-bottom: 4px;
  }
}
</style>
