<script>
import Edit from './Edit'
import View from './View'
export default {
  functional: true,
  render(h, context) {
    const { mode } = context.props
    return h(mode === 'view' ? View : Edit, context.data, context.children)
  }
}
</script>
