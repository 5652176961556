<template>
  <div>
    <fileItem v-for="(file, index) in items" :key="index" :file="file">
      <div class="xa-cell">
        <template v-if="file.href">
          <a :href="file.href" target="_blank" :name="file.name"
            ><i class="el-icon-share" title="查看"></i
          ></a>
        </template>
        <div v-if="removable" @click="$emit('delete', file)">
          <i class="el-icon-delete"></i>
        </div>
      </div>
    </fileItem>
  </div>
</template>
<script>
import FileItem from './FileItem.vue'
export default {
  components: {
    FileItem
  },
  props: {
    items: Array,
    removable: Boolean
  }
}
</script>
<style lang="scss" scoped>
[class*='el-icon'] {
  cursor: pointer;
  color: #333;
}
.el-icon-delete {
  margin-left: 16px;
  &:hover {
    color: $color-red;
  }
}
</style>
