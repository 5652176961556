<template>
    <div class="container">
        <div class="bar">
            <span class="bar-unfill">
                <div :data-progress="progress+'%'" :style="{'width':progress+'%'}" class="bar-fill-stripes"></div>
            </span>
        </div>
    </div>
</template>
<script>
export default {
    name: 'progressBar',
    props: {
        progress: {
            type: Number
        }
    }
}
</script>
<style scoped>
.container {
    width: 100%;
    margin: 0 auto
}

.bar-unfill {
    height: 15px;
    display: block;
    background: #fff;
    width: 100%;
    border-radius: 8px;
}

.bar-fill {
    height: 15px;
    display: block;
    background: #45c9a5;
    width: 0;
    border-radius: 8px;
    transition: width .8s ease;
    animation: progressbar 7s infinite
}

.bar-fill-stripes {
    position: relative;
    height: 15px;
    display: block;
    background: #04bf02;
    width: 0;
    border-radius: 8px;
    background-image: linear-gradient(-45deg, rgba(255, 255, 255, .2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .2) 50%, rgba(255, 255, 255, .2) 75%, transparent 75%, transparent);
    transition: width 1s ease;
    overflow: hidden;
}

.bar-fill-stripes::before {
    content: attr(data-progress);
    position: absolute;
    top: 0;
    top: 50%;
    right: 8px;
    color: #fff;
    font-size: 10px;
    transform: translate(0, -50%);
}

</style>

